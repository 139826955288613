<script>
    import RightExpandPanelLayout from '@layouts/Dialogs/RightExpandPanelLayout.vue'
    import { mapActions, mapState } from 'pinia'
    import { useMDMStore } from '@store/mdm.js'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import HeadsetStatusCompact from '@components/Headsets/HeadsetStatusCompact.vue'
    import { HeadsetRoutesNames } from '@routes/generators/Headset.names.js'
    import HeadsetControllersStatusCompact from '@components/Headsets/HeadsetControllersStatusCompact.vue'
    import HeadsetRenameDialog from '@components/Headsets/Details/HeadsetRenameDialog.vue'
    import HeadsetChangeUserConnected from '@components/Headsets/Details/HeadsetChangeUserConnected.vue'
    export default {
        components: {
            HeadsetChangeUserConnected,
            HeadsetRenameDialog,
            HeadsetControllersStatusCompact,
            HeadsetStatusCompact,
            RightExpandPanelLayout,
        },
        mixins: [mixins.RaDialogsMixin],
        data() {
            return {
                headset: null,
                currentTab: 0,
            }
        },
        computed: {
            ...mapState(useMDMStore, [
                'getHeadset',
                'syncedHeadsets',
                'notSyncedHeadsets',
                'headsetsAppDriven',
                'companyHeadsets',
                'MDM_LOADING',
                'companyDevices',
                'fetchCompanyHeadsets',
            ]),
            serialNumber() {
                return this.$route.params.serialNumber
            },

            tabs() {
                return [
                    {
                        name: 'Paramètres',
                        route: {
                            name: HeadsetRoutesNames.settings,
                        },
                    },
                    {
                        name: 'Bibliothèque',
                        route: {
                            name: HeadsetRoutesNames.library,
                        },
                    },
                    {
                        name: 'Groupe',
                        route: {
                            name: HeadsetRoutesNames.group,
                        },
                    },
                    {
                        name: 'Informations',
                        route: {
                            name: HeadsetRoutesNames.infos,
                        },
                    },
                    {
                        name: 'Notes',
                        route: {
                            name: HeadsetRoutesNames.notes,
                        },
                    },
                ]
            },
        },
        mounted() {
            this.openDialog('dialog-headset-view')
            this.headset = this.getHeadset(this.serialNumber)
            if (!this.headset) {
                this.mdmOn('syncedHeadsets', () => {
                    if (this.headset) return
                    this.headset = this.getHeadset(this.serialNumber)
                })
            }
        },
        methods: {
            ...mapActions(useMDMStore, [
                'mdmOn',
                'setCompanyID',
                'startMDM',
                'handleStartFormation',
                'handleStream',
            ]),

            backToList() {
                setTimeout(() => {
                    this.$router.push({
                        name: this.$route.name.replace(/[.].+$/, '.index'),
                    })
                }, 400)
            },

            handleStartFormationBtn() {
                this.handleStream([this.headset], () => {
                    this.$router.push({
                        name: HeadsetRoutesNames.startSession,
                        query: {
                            back: this.$route.fullPath,
                        },
                    })
                })
            },
            handleStreamBtn() {
                this.handleStream([this.headset], () => {
                    this.$router.push({
                        name: HeadsetRoutesNames.drive,
                        query: {
                            back: this.$route.fullPath,
                        },
                    })
                })
            },
        },
    }
</script>

<template>
    <ra-dialog
        ref="dialog-headset-view"
        expand-panel
        @close="backToList"
    >
        <right-expand-panel-layout v-if="headset">
            <template #title> Détails du casque </template>
            <template #header>
                <ra-stack
                    direction="horizontal"
                    padding="pb-4"
                    alignement="center"
                    gap="8"
                    mobile-swap
                >
                    <ra-box width="120px">
                        <img
                            :src="headset.image"
                            alt="headset"
                            width="120"
                        />
                    </ra-box>
                    <ra-stack>
                        <ra-stack
                            direction="horizontal"
                            alignement="center"
                        >
                            <ra-typo
                                variant="main-title"
                                semi
                            >
                                {{ headset.name }}
                            </ra-typo>
                            <v-btn
                                variant="plain"
                                icon
                                density="comfortable"
                                @click="openDialog('dialog-rename-headset')"
                            >
                                <ra-icon>fa-edit</ra-icon>
                            </v-btn>
                        </ra-stack>
                        <ra-stack
                            direction="horizontal"
                            mobile-swap
                        >
                            <v-btn
                                color="primary-gradient"
                                variant="flat"
                                :disabled="!headset.isDrivable"
                                @click="handleStreamBtn"
                            >
                                <ra-stack
                                    direction="horizontal"
                                    gap="3"
                                >
                                    <ra-icon class="streamIconBTN"
                                        >$headset-stream</ra-icon
                                    >
                                    <ra-typo span>Streamer</ra-typo>
                                </ra-stack>
                            </v-btn>

                            <v-btn
                                variant="plain"
                                :disabled="!headset.isDrivable"
                                @click="handleStartFormationBtn"
                            >
                                <ra-typo
                                    span
                                    variant="small"
                                >
                                    <ra-stack
                                        direction="horizontal"
                                        gap="4"
                                    >
                                        <ra-icon weight="bold">
                                            fa-play
                                        </ra-icon>
                                        Lancez une formation
                                    </ra-stack>
                                </ra-typo>
                            </v-btn>
                        </ra-stack>
                    </ra-stack>
                </ra-stack>
                <v-divider class="compense-padding-x" />
                <ra-stack
                    direction="horizontal"
                    class="compense-padding-bottom"
                    distribution="between"
                    padding="2,"
                    mobile-swap
                >
                    <ra-box>
                        <ra-typo
                            span
                            variant="small"
                            >Utilisateur connecté</ra-typo
                        >
                        <ra-typo
                            span
                            underline
                            margin="ml-2"
                            variant="small"
                            >Jane Doe</ra-typo
                        >
                        <v-btn
                            variant="plain"
                            icon
                            density="comfortable"
                            @click="openDialog('dialog-change-user')"
                        >
                            <ra-icon>fa-edit</ra-icon>
                        </v-btn>
                    </ra-box>
                    <ra-box centered-content>
                        <ra-stack
                            direction="horizontal"
                            alignement="center"
                        >
                            <ra-image
                                src="/images/MDM/manette.png"
                                width="24"
                                height="24"
                                alt="manette"
                            />
                            <ra-typo
                                no-word-wrap
                                variant="small"
                                style="opacity: 0.8"
                                >{{
                                    $t(
                                        'headsets.properties.controller.values.left_long'
                                    )
                                }}</ra-typo
                            >
                            <ra-box
                                ><headset-controllers-status-compact
                                    :headset="headset"
                                    only="left"
                            /></ra-box>
                        </ra-stack>
                    </ra-box>
                </ra-stack>

                <ra-box class="connectivity-status">
                    <HeadsetStatusCompact :headset="headset" />
                </ra-box>
            </template>
            <ra-box
                bg="transparent"
                class="compense-padding-x compense-padding-top"
            >
                <ra-tabs
                    v-model="currentTab"
                    :tabs="tabs"
                    :headset="headset"
                />
            </ra-box>
        </right-expand-panel-layout>
    </ra-dialog>
    <ra-dialog ref="dialog-rename-headset">
        <headset-rename-dialog :headset="headset" />
    </ra-dialog>
    <ra-dialog ref="dialog-change-user">
        <headset-change-user-connected :headset="headset" />
    </ra-dialog>
</template>
<style lang="scss">
    .streamIconBTN svg {
        transform: scale(1.3);
    }
</style>
<style scoped lang="scss">
    .compense-padding-x {
        margin: 0 -16px;
    }
    .compense-padding-top {
        margin-top: -16px;
    }
    .compense-padding-bottom {
        margin-bottom: -16px;
    }
    .connectivity-status {
        position: absolute;
        right: 24px;
        top: 24px;
    }
</style>
