import { godRoutesMeta as meta } from '@core/router/routes/God/meta.js'
import { CompaniesRoutesName } from '@core/router/routes/God/Companies.names.js'

export default [
    {
        path: '/companies',
        component: () => import('@pages/Companies/CompaniesPage.vue'),
        name: CompaniesRoutesName.index,
        meta,
    },
    {
        path: '/companies/:company_id',
        component: () => import('@pages/Companies/CompanyShowPage.vue'),
        children: [
            {
                path: '',
                name: CompaniesRoutesName.show,
                meta,
                redirect: {
                    name: CompaniesRoutesName.profile,
                },
            },
            {
                path: 'profile',
                name: CompaniesRoutesName.profile,
                meta: {
                    relatedName: CompaniesRoutesName.show,
                    ...meta,
                },
                component: () =>
                    import('@components/Companies/CompanyView.vue'),
            },
            {
                path: 'licenses',
                name: CompaniesRoutesName.licenses,
                meta,
                component: () =>
                    import('@components/Licenses/LicenseTableList.vue'),
            },
            {
                path: 'formers',
                name: CompaniesRoutesName.formers,
                meta,
                component: () =>
                    import('@components/Formers/FormerTableList.vue'),
                children: [
                    {
                        path: ':former_id',
                        name: CompaniesRoutesName.formerProfile,
                        meta,
                        component: () =>
                            import('@components/Formers/FormerInfoDialog.vue'),
                    },
                ],
            },
            {
                path: 'learners',
                name: CompaniesRoutesName.learners,
                meta,
                component: () =>
                    import('@components/Learners/LearnerTableList.vue'),
                children: [
                    {
                        path: ':learner_id',
                        children: [
                            {
                                path: '',
                                name: CompaniesRoutesName.learnerProfile,
                                component: () =>
                                    import(
                                        '@components/Learners/LearnerInfoDialog.vue'
                                    ),
                                children: [
                                    {
                                        path: 'profile',
                                        name: CompaniesRoutesName.infoTab,
                                        component: () =>
                                            import(
                                                '@components/Learners/Tabs/LearnerInfoTab.vue'
                                            ),
                                    },
                                    {
                                        path: 'formations',
                                        name: CompaniesRoutesName.formationTab,
                                        component: () =>
                                            import(
                                                '@components/Learners/Tabs/LearnerFormationsTab.vue'
                                            ),
                                    },
                                    {
                                        path: 'sessions',
                                        name: CompaniesRoutesName.sessionTab,
                                        component: () =>
                                            import(
                                                '@components/Learners/Tabs/LearnerSessionTab.vue'
                                            ),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'headsets',
                name: CompaniesRoutesName.headsets,
                meta,
                component: () =>
                    import('@components/Headsets/SubHeadsetsTab.vue'),
            },
            {
                path: 'stats',
                name: CompaniesRoutesName.stats,
                component: () =>
                    import('@components/Stats/SubStatistiquesView.vue'),
                meta,
            },
        ],
    },
]
