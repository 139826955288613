<script setup></script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $tc('headsets.actions.rename', 2) }}
        </template>
        <ra-input>
            <ra-user-selector
                v-model="referent_id"
                :company_id="company.id"
                :label="$t('learners.affect_former.label')"
                class="mb-4"
            />
        </ra-input>
    </ra-base-dialog>
</template>

<style scoped lang="scss"></style>
