<script>
    import { Headset } from '@libs/MDM/core/Headset.js'
    import { humanizeBytes } from '@utils/humanizeBytes.js'

    export default {
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },
        methods: {
            prefixer(key, val, fallback = 'UNKNOWN') {
                if (!val) return fallback
                return this.$t(key + '.' + val)
            },
        },
        computed: {
            content() {
                return [
                    {
                        title: 'headsets.settings.headset_application.title',
                        properties: {
                            'headsets.settings.headset_application.last_launch':
                                {
                                    content: () => {
                                        return this.$filters.formatDate(
                                            this.headset?.state
                                                ?.applicationMonitoring
                                                .lastStart || 'UNKNOWN'
                                        )
                                    },
                                },

                            'headsets.settings.headset_application.kiosk_mode.label':
                                this.prefixer(
                                    'headsets.settings.headset_application.kiosk_mode',
                                    this.headset?.state?.applicationMonitoring
                                        ?.kioskModeEnabled
                                        ? 'yes'
                                        : 'no',
                                    'UNKNOWN'
                                ),

                            'headsets.settings.headset_application.lang.label':
                                this.prefixer(
                                    'headsets.settings.headset_application.lang',
                                    this.headset?.state?.applicationMonitoring
                                        ?.language
                                ),
                        },
                    },
                    {
                        title: 'headsets._name',
                        properties: {
                            'headsets.properties.model.label':
                                this.headset.state.model,
                            'headsets.properties.version.headset':
                                this.headset.state.releaseInstalled.version ||
                                'UNKNOWN',
                            'headsets.properties.version.android':
                                this.headset.state.androidVersion,
                            'headsets.properties.disk.label': {
                                content: () => {
                                    return (
                                        humanizeBytes(
                                            this.headset.state.storageUse
                                        ) +
                                        ' / ' +
                                        humanizeBytes(
                                            this.headset.state.storageSize
                                        )
                                    )
                                },
                                attrs: {
                                    color:
                                        this.headset.state.storageUse /
                                            this.headset.state.storageSize >
                                        0.9
                                            ? 'error'
                                            : 'default',
                                },
                            },
                            'headsets.properties.serialNumber.label':
                                this.headset.serialNumber,
                            'headsets.properties.control.label': 'UNKNOWN', // @TODO add control when api persit data
                        },
                    },
                    {
                        title: 'headsets.settings.headset_network.title',
                        properties: {
                            'headsets.settings.headset_network.name':
                                this.headset.networkInformations.name,
                            'headsets.settings.headset_network.ip':
                                this.headset.networkInformations.ipAddress,
                            'headsets.settings.headset_network.mac':
                                this.headset.networkInformations.macAddress,
                        },
                    },
                ]
            },
        },
    }
</script>

<template>
    <ra-stack
        padding="0,6"
        gap="4"
    >
        <ra-card
            v-for="card in content"
            :key="card.title"
        >
            <template #title>
                {{ $t(card.title) }}
            </template>
            <ra-box padding="3">
                <ra-typo variant="small">
                    <table>
                        <tr
                            v-for="(prop, i) in Object.keys(card.properties)"
                            :key="'prop-' + i"
                        >
                            <td>
                                <ra-box style="width: 160px">
                                    <ra-typo
                                        span
                                        semi
                                    >
                                        {{ $t(prop) }}
                                    </ra-typo>
                                </ra-box>
                            </td>
                            <td>
                                <template v-if="card.properties[prop]?.content">
                                    <ra-typo
                                        v-bind="card.properties[prop]?.attrs"
                                    >
                                        {{
                                            $t(card.properties[prop]?.content())
                                        }}
                                    </ra-typo>
                                </template>
                                <ra-typo v-else>
                                    {{ $t(card.properties[prop]) }}
                                </ra-typo>
                            </td>
                        </tr>
                    </table>
                </ra-typo>
            </ra-box>
        </ra-card>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
