import { defineStore } from 'pinia'
import { useUserStore } from '@store/user.js'
import { CompaniesProvider } from '@providers/CompaniesProvider.js'
import { CompanyStructure } from '@libs/Structures/CompanyStructure.js'

export const useCurrentCompanyViewed = defineStore('currentCompanyViewed', {
    state: () => ({
        companyID: null,
        company: null,
        _loader: null,
    }),

    actions: {
        setLoader(loader) {
            this._loader = loader
        },
        setCompanyID(companyID) {
            this.companyID = companyID
        },
        setCompany(data) {
            this.company = new CompanyStructure(data)
            this.company.is_demo = Boolean(this.company.is_demo)
        },

        initializeFromUserStore() {
            const userStore = useUserStore()
            if (userStore.user && userStore.user.company_id) {
                this.companyID = userStore.user.company_id
                this.setCompany(userStore.user.company)
            }
        },

        initializeFromRouteParams(route) {
            if (route.params.company_id) {
                this.companyID = route.params.company_id
                this.fetchCompany()
            }
        },

        async fetchCompany() {
            if (!this.companyID) return
            let request = await CompaniesProvider.getCompany(this.companyID)
            if (this._loader) request.registerLoader(this._loader)
            const { data } = await request.call()
            this.setCompany(data)
        },

        initialize(route) {
            this.reset()
            this.initializeFromRouteParams(route)
            if (!this.companyID) this.initializeFromUserStore()
            if (!this.companyID) throw new Error('No company ID found')
        },
        reset() {
            this.companyID = null
            this.company = null
        },
    },
})
